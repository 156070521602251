import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { API } from 'aws-amplify'
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined'
import DownloadDoneIcon from '@mui/icons-material/DownloadDone'
import DownloadingIcon from '@mui/icons-material/Downloading'
import GetAppIcon from '@mui/icons-material/GetApp'
import LaunchIcon from '@mui/icons-material/Launch'
import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Input,
  Modal,
  Pagination,
  Skeleton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'
import sendIcon from '../../../assets/images/sendIcon.svg'
import '../../../assets/styles/AccountRequest.scss'
import { useAuth } from '../../../contexts/AuthContext'
import { useErrorToast } from '../../../hooks/useErrorToast'
import RequestForm from './RequestForm'
import CustomKeyFieldSearch from '../../../components/CustomKeyFieldSearch'
import CustomNoRowsOverlay from './CustomNoRowsOverlay'
import AllComments from '../../../components/AllComments'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '4px'
}
const ViewStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  maxHeight: 625,
  height: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '5px'
}

const SponsorRequest = () => {
  const { user } = useAuth()
  const { showError } = useErrorToast()
  const [postShown, setPostShown] = useState(false)
  const [totalPage, setTotalPage] = useState(1)
  const [noData, setNodata] = useState()
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [rows, setRows] = useState(null)

  const handleClose = () => {
    setPostShown(false)
  }

  useEffect(() => {
    if (user) {
      getAllSponsorData()
    }
  }, [user])

  const getAllSponsorData = async (page = 1, searchTerm = '') => {
    const queryParams = {
      page,
      'per-page': 10
    }
    if (searchTerm.trim().length > 2) {
      queryParams.userRequestId = searchTerm.toUpperCase()
    }
    setLoading(true)
    API.get('baseRequestURL', `request/v1/${user.userGroup}/ips/requests`, { queryStringParameters: { ...queryParams } })
      .then(response => {
        if (response.data) {
          setNodata(response.data.length ? null : response.message)
          setRows(response?.data?.reqData || [])
          setTotalPage(Number(response.data?.pagination?.totalPages || 0))
          setPage(response.data?.pagination?.currentPage || 0)
        }
      }).catch(error => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => setLoading(false))
  }

  const handleChangePage = (event, value) => {
    if (value !== page) {
      setPage(value)
      getAllSponsorData(value)
    }
  }
  // call search API if search text has minimun 3 character
  // call initial get API if search text is cleared.
  const handleSearchTextChange = (searchTerm) => {
    if (!searchTerm?.trim().length || searchTerm?.trim().length > 2) {
      setPage(1)
      getAllSponsorData(1, searchTerm)
    }
  }

  return (
    <>
      <Modal open={postShown} onClose={handleClose} centered>
        <Box sx={style}>
          <RequestForm handleClose={handleClose} />
        </Box>
      </Modal>
      <Box mt={1}>
        {user?.userGroup === 'pm'
          ? <Box
              textAlign='right' sx={{
                position: 'absolute',
                right: '16px',
                top: '16px'
              }}
            />
          : ''}
        {rows
          ? <Box sx={{ position: 'absolute', top: '16px', right: '16px' }}>
            <CustomKeyFieldSearch fieldName='userRequestId' handleSearchTextChange={handleSearchTextChange} fieldLabel='Request ID' />
          </Box>
          : ''}
        {!loading
          ? (
            <RequestList data={rows || []} isFirstPage={page === 1} handleSearchTextChange={handleSearchTextChange} noDataMessage={noData} />
            )
          : <Stack spacing={2}>
            <Skeleton variant='rounded' animation='wave' height={40} />
            <Skeleton variant='rounded' animation='wave' height={40} />
            <Skeleton variant='rounded' animation='wave' height={40} />
            <Skeleton variant='rounded' animation='wave' height={40} />
            <Skeleton variant='rounded' animation='wave' height={40} />
          </Stack>}
        {
          rows?.length === 0
            ? (
                ''
              )
            : (
              <Box className='Pagnate'>
                <Pagination
                  count={totalPage}
                  page={page}
                  onChange={handleChangePage}
                  sx={{
                    mt: 2,
                    '.MuiPagination-ul': {
                      justifyContent: 'flex-end'
                    }
                  }}
                />
              </Box>
              )
        }
      </Box>
    </>
  )
}

export default SponsorRequest

export const RequestList = ({ data, isFirstPage, noDataMessage }) => {
  const [isShown, setIsShown] = useState(false)
  const [editshow, setEditShow] = useState(false)
  const [editIndex, setEditIndex] = useState()
  const navigate = useNavigate()
  const [downloadingIds, setDownloadingIds] = useState([])
  const [downloadedIds, setDownloadedIds] = useState([])
  const { user } = useAuth()
  const { showError } = useErrorToast()

  const handleView = (index) => {
    setEditIndex(index)
    setIsShown(true)
  }

  const handleDownload = async (row) => {
    if (!downloadingIds.includes(row.requestId)) {
      try {
        setDownloadingIds(prevData => [...prevData, row.requestId])
        await API.get('baseUriSubAdvisoryPolicy', `sub-advisory-policy/v1/${user.userGroup}/ips/${row.requestId}/statements`)
          .then(async (response) => {
            const body = {
              sponsorCd: row?.sponsorCd || '',
              sponsorName: row?.sponsorName || '',
              documentId: row.documentId || '',
              documentType: 'LATEST',
              policyGroups: response.data || []
            }
            await API.post('baseUriSubAdvisoryPolicy', `sub-advisory-policy/v1/${user.userGroup}/ips/policy-document/${row?.sponsorId}`, {
              responseType: 'text',
              body
            })
              .then((response) => {
                const base64Response = response
                const byteCharacters = atob(base64Response)
                const byteNumbers = new Array(byteCharacters.length)
                for (let i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i)
                }
                const byteArray = new Uint8Array(byteNumbers)
                const blobData = new Blob([byteArray], { type: 'application/octet-stream' })
                const url = window.URL.createObjectURL(new Blob([(blobData)]))
                const fileLink = document.createElement('a')
                fileLink.href = url
                fileLink.download = 'Sub-advisory-policy-statement.pdf'
                document.body.appendChild(fileLink)
                fileLink.click()
                URL.revokeObjectURL(url)
                document.body.removeChild(fileLink)
                setDownloadingIds(prevData => prevData.filter(id => id !== row.requestId))
                setDownloadedIds(prevData => [...prevData, row.requestId])
                setTimeout(() => {
                  setDownloadedIds(prevData => prevData.filter(id => id !== row.requestId))
                }, 3000)
              })
          })
      } catch (error) {
        setDownloadingIds(prevData => prevData.filter(id => id !== row.requestId))
        showError(error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      }
    }
  }

  const columns = [
    {
      field: 'requestStatus',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Stack className={params.row.requestStatus !== 'Approved' ? 'awaiting-badge' : 'completed-badge'}>
            {params.value}
          </Stack>
        </Box>
      )
    },

    {
      field: 'sponsorCd',
      headerName: 'Sponsor Code',
      flex: 1
    },
    {
      field: 'userRequestId',
      headerName: 'Request ID',
      flex: 1
    },
    {
      field: 'documentId',
      headerName: 'Document ID',
      flex: 1,
      valueGetter: (params) =>
        `DOCU${params.row?.userRequestId?.substring(4)}`
    },
    {
      field: 'requestType',
      headerName: 'Request Type',
      flex: 1,
      valueGetter: (params) => params.row.requestType || 'N/A'
    },
    {
      field: 'title',
      headerName: 'Request Title',
      flex: 1,
      valueGetter: (params) => params.row.title || 'N/A'
    },
    {
      field: 'startDate',
      headerName: 'Created On',
      flex: 1,
      valueFormatter: (params) =>
        params.value ? new Date(params.value).toDateString() : '--------'
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      sortable: false,
      maxWidth: 100,
      disableColumnMenu: true,
      resizable: false,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title='View Comments'>
            <IconButton onClick={() => handleView(params.row.index)}>
              <ChatOutlinedIcon sx={{ fontSize: 18 }} />
            </IconButton>
          </Tooltip>
          <Tooltip title={isFirstPage && params.row.index === 0 ? 'Redirect' : 'Download'}>
            <IconButton onClick={() => showPolicyStatus(params.row)}>
              {isFirstPage && params.row.index === 0 && (params.row.requestStatusCd !== 'CANCELLED' && params.row.requestStatusCd !== 'REJECTED')
                ? (
                  <>
                    <LaunchIcon sx={{ color: '#616161', fontSize: 18 }} />
                    <span style={{ display: 'none' }}>Redirect</span>
                  </>
                  )
                : downloadingIds.includes(params.row.requestId) && !downloadedIds.includes(params.row.requestId)
                  ? (
                    <>
                      <DownloadingIcon sx={{ fontSize: 18, color: '#616161' }} />
                      <span style={{ display: 'none' }}>Download</span>
                    </>
                    )
                  : downloadedIds.includes(params.row.requestId)
                    ? (
                      <>
                        <DownloadDoneIcon sx={{ fontSize: 18, color: 'green' }} />
                        <span style={{ display: 'none' }}>Download</span>
                      </>
                      )
                    : (
                      <>
                        <GetAppIcon sx={{ fontSize: 18, color: '#616161' }} />
                        <span style={{ display: 'none' }}>Download</span>
                      </>
                      )}
            </IconButton>
          </Tooltip>

        </Box>
      )
    }
  ]

  const showPolicyStatus = (row) => {
    if (row.index === 0 && isFirstPage && (row.requestStatusCd !== 'CANCELLED' && row.requestStatusCd !== 'REJECTED')) {
      localStorage.setItem('ips-sponsor-id', row.sponsorId)
      navigate('/ips/policy')
    } else {
      if (!downloadingIds.includes(row.requestId)) {
        handleDownload(row)
      }
    }
  }

  return (
    <>
      <Modal open={isShown} onClose={() => setIsShown(false)} centered>
        <Box sx={ViewStyle}>
          <ViewRequest selectedItem={data[editIndex]} />
        </Box>
      </Modal>
      <Modal open={editshow} onClose={() => setEditShow(false)} centered>
        <Box sx={style} />
      </Modal>
      <DataGridPro
        autoHeight
        rows={data ? data?.map((row, index) => ({ ...row, index })) : []}
        columns={columns}
        getRowId={(row) => row?.requestId}
        hideFooter
        slots={{
          noRowsOverlay: CustomNoRowsOverlay
        }}
        slotProps={{
          noRowsOverlay: { message: noDataMessage }
        }}
        sx={{
          border: 'none',
          fontFamily: 'Open Sans',
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 600,
            fontFamily: 'Open Sans',
            color: '#34475A'
          },
          '& .MuiDataGrid-columnHeaderTitleContainerContent': {
            fontWeight: 600,
            fontFamily: 'Open Sans',
            color: '#34475A'
          }
        }}
      />
    </>
  )
}

const LoadingSkeletonViewReq = () => {
  return (
    <Box className='skel'>
      <Skeleton height={80} animation='wave' />
      <Skeleton height={60} animation='wave' />
      <Skeleton height={60} animation='wave' />
      <Skeleton height={60} animation='wave' />
      <Skeleton height={60} animation='wave' />
    </Box>
  )
}

export const ViewRequest = ({ selectedItem }) => {
  const { requestType, title, requestId, requestStatusCd } = selectedItem
  const { user } = useAuth()
  const { showError } = useErrorToast()
  const [commentRequests, setCommentRequests] = useState([])
  const [commentValue, setCommentValue] = useState('')

  const getCommentRequests = () => {
    setLoading(true)
    API
      .get('baseRequestURL', `request/v1/${user.userGroup}/ips/comments/${requestId}`, { queryStringParameters: { page: 1, perPage: 10 } })
      .then(response => {
        if (response.data) {
          setCommentRequests(response.data.commentData)
        }
      })
      .catch(error => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => setLoading(false))
  }
  useEffect(() => {
    if (user) {
      getCommentRequests()
    }
  }, [])

  const handleInputChange = (event) => {
    const sanitizedComment = event.target.value.replace(/(<([^>]+)>)/gi, '')
    setCommentValue(sanitizedComment)
  }

  const handlePostComment = (event) => {
    event.preventDefault()
    if (commentValue?.length > 0) {
      API.post(
        'baseRequestURL',
        `request/v1/${user.userGroup}/ips/comments/${requestId}`,
        { body: { comment: commentValue } }
      )
        .then((response) => {
          setCommentRequests((comments) => [response.data[0], ...comments])
        })
        .catch((error) => {
          showError(error.response?.data?.errorInfo?.userMessage || error.message)
          Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
        })
      setCommentValue('')
    }
  }

  const [loading, setLoading] = useState(true)

  if (loading) {
    return <LoadingSkeletonViewReq />
  }

  return (
    <>
      <Grid container>
        <Grid container spacing={2} className='modal_area' sx={{ width: '100%' }}>
          <Grid item xs={12}>
            <Grid container justifyContent='flex-start' alignItems='center' mb={2}>
              <Grid item>
                <Typography variant='h6' style={{ width: '100%', fontWeight: 400, fontFamily: 'Lora', color: '#34475A' }}>
                  View Request
                </Typography>
              </Grid>
              <Grid item>
                <Box className='divider' mx={2} />
              </Grid>
              <Grid item>
                <Typography sx={{ fontSize: '20px' }} fontFamily='Open Sans'>{selectedItem.sponsorCd}</Typography>
                <Typography fontSize='12px' fontWeight='500' fontFamily='Open Sans'>{selectedItem.userRequestId}  {'->'}  {selectedItem.requestType}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container className='form-req'>
          <Grid item xs={12} sx={{ mb: 1 }}>
            <FormLabel className='viewReq account-request-label'>Comment</FormLabel>
            {commentRequests.length ? <AllComments data={commentRequests || []} /> : <p>N/A</p>}
            {(selectedItem?.requestStatusCd === 'CANCELLED' || selectedItem?.requestStatusCd === 'REJECTED')
              ? ''
              : <Box className='reqEditCommnet'>
                <FormControl fullWidth>
                  <Input
                    className='form-control'
                    placeholder='Write a Comment here'
                    required
                    sx={{ paddingX: '10px' }}
                    value={commentValue}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <img
                  className='sendIcon' src={sendIcon} alt='' onClick={handlePostComment}
                />
                </Box>}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
