import React, { useCallback, useLayoutEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined'
// import ELK from 'elkjs/lib/elk.bundled.js'
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  useReactFlow,
  Controls,
  ControlButton
} from 'reactflow'

import { formatCurrencyWithSymbol } from '../../../../utils/FormateCurrenyInMilion'
import clickIcon from '../../../../assets/images/icon-click.svg'

// const elk = new ELK()

// const elkOptions = {
//   'elk.direction': 'DOWN',
//   'elk.algorithm': 'mrtree',
//   'elk.layered.spacing.nodeNodeBetweenLayers': '100',
//   'elk.spacing.nodeNode': '80'
// }

// const getLayoutedElements = (nodes, edges, options = {}) => {
//   const isHorizontal = options?.['elk.direction'] === 'RIGHT'
//   const graph = {
//     id: 'root',
//     layoutOptions: options,
//     children: nodes.map((node) => ({
//       ...node,
//       // Adjust the target and source handle positions based on the layout
//       // direction.
//       targetPosition: isHorizontal ? 'left' : 'top',
//       sourcePosition: isHorizontal ? 'right' : 'bottom',

//       // Hardcode a width and height for elk to use when layouting.
//       width: 200,
//       height: 50
//     })),
//     edges
//   }

//   return elk
//     .layout(graph)
//     .then((layoutedGraph) => ({
//       nodes: layoutedGraph.children.reduce((result, current) => {
//         result.push({
//           ...current,
//           position: { x: current.x, y: current.y }
//         });

//         (current.children || []).forEach((child) =>
//           result.push({
//             ...child,
//             position: { x: child.x, y: child.y },
//             parentNode: current.id
//           })
//         )
//         return result
//       }, []),

//       edges: layoutedGraph.edges
//     }))
//     .catch(console.error)
// }

function LayoutFlow ({ aggregateData }) {
  const createNodeHTML = (nodeData) => {
    let node = {
      data: {
        label: <Box className='inner-box'>
          <Typography fontSize='14px' className='tree-padding'>{nodeData?.nodeInfo?.name}</Typography>
          <Box display='flex' justifyContent='space-between' className='tree-padding'>
            <Typography fontSize='14px' className='row-div'>Market Value <span>{nodeData?.nodeInfo?.marketValue !== undefined && nodeData?.nodeInfo?.marketValue !== null ? formatCurrencyWithSymbol(nodeData?.nodeInfo?.marketValue, 2, '$') : 'NA'}</span></Typography>
            {nodeData?.nodeInfo?.category === 'ips' || nodeData?.nodeInfo?.category === 'account'
              ? <Typography fontSize='14px' className='row-div'>Tracking Error <span>{nodeData?.nodeInfo?.trackingError === null || nodeData?.nodeInfo?.trackingError === undefined ? 'NA' : `${nodeData?.nodeInfo?.trackingError}%`}</span></Typography>
              : <Typography fontSize='14px' className='row-div'>Total Risk <span>{nodeData?.nodeInfo?.totalRisk === null || nodeData?.nodeInfo?.totalRisk === undefined ? 'NA' : `${nodeData?.nodeInfo?.totalRisk}%`}</span></Typography>}
          </Box>
        </Box>
      }
    }
    if (nodeData?.nodeInfo?.type === 'group') {
      node = {
        data: { label: null }
      }
    }
    if (nodeData?.nodeInfo?.nodeType === 'strategy') {
      node = {
        data: {
          label: <>
            {/* <Box sx={{ position: 'absolute', top: '-25px', left: 0, right: 0, display: 'flex', justifyContent: 'space-between' }} className='tree-padding'>
            {nodeData?.nodeInfo?.allocation}
            {
                            nodeData?.nodeInfo?.parentAccountData
                              ? <Box display='flex' alignItems='center'>{nodeData?.nodeInfo?.parentAccountData.map((obj) => (
                                <Box key={obj.id}>
                                  <Box sx={{ bgcolor: obj.color, height: '14px', width: '14px', borderRadius: '50%', mr: '5px' }} />
                                </Box>
                              ))}
                                </Box>
                              : ''
                        }
            </Box> */}
            <Box className='inner-box'>
              <Typography fontSize='14px' className='tree-padding'>{nodeData?.nodeInfo?.name}</Typography>
              <Box className='tax-round'>{nodeData?.nodeInfo?.isTaxable ? 'T' : 'NT'}</Box>
              <Box display='flex' justifyContent='space-between' className='tree-padding'>
                <Typography fontSize='14px' className='row-div'>Market Value <span>{nodeData?.nodeInfo?.marketValue !== undefined && nodeData?.nodeInfo?.marketValue !== null ? formatCurrencyWithSymbol(nodeData?.nodeInfo?.marketValue, 2, '$') : 'NA'}</span></Typography>
                <Typography fontSize='14px' className='row-div'>Tracking Error <span>{nodeData?.nodeInfo?.trackingError === null || nodeData?.nodeInfo?.trackingError === undefined ? 'NA' : `${nodeData?.nodeInfo?.trackingError}%`}</span></Typography>
              </Box>
            </Box>
                 </>
        }
      }
    }
    if (nodeData?.nodeInfo?.parentData) {
      node = {
        data: {
          label: (
            <Box className='inner-box'>
              <Typography fontSize='14px' className='tree-padding'>{nodeData?.nodeInfo?.name}</Typography>
              <Box className='tax-round'>{nodeData?.nodeInfo?.isTaxable ? 'T' : 'NT'}</Box>
              <Box display='flex' justifyContent='space-between' className='tree-padding'>
                <Typography fontSize='14px' className='row-div'>Market Value <span>{nodeData?.nodeInfo?.marketValue !== undefined && nodeData?.nodeInfo?.marketValue !== null ? formatCurrencyWithSymbol(nodeData?.nodeInfo?.marketValue, 2, '$') : 'NA'}</span></Typography>
                <Typography fontSize='14px' className='row-div'>Total Risk <span>{nodeData?.nodeInfo?.totalRisk === null || nodeData?.nodeInfo?.totalRisk === undefined ? 'NA' : `${nodeData?.nodeInfo?.totalRisk}%`}</span></Typography>
              </Box>
              {
                                nodeData?.nodeInfo?.parentData.map((obj) => (
                                  <Box key={obj.id} display='flex' alignItems='center' justifyContent='space-between' fontSize='14px' className='tree-padding'>
                                    <Box display='flex' alignItems='center'><Box sx={{ bgcolor: obj.color, height: '14px', width: '14px', borderRadius: '50%', mr: '5px' }} /> <Typography fontSize='14px' whiteSpace='nowrap'>{obj.name}</Typography></Box>
                                    <Typography fontSize='14px'>{obj.allocation}</Typography>
                                  </Box>
                                ))
                            }
            </Box>
          )
        }
      }
    }
    return node
  }

  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])
  const { fitView } = useReactFlow()
  const navigate = useNavigate()

  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [])

  // Calculate the initial layout on mount.
  useLayoutEffect(() => {
    // onLayout({ direction: 'DOWN', useInitialNodes: true })
    if (aggregateData?.nodes && aggregateData?.edges) {
      setNodes(aggregateData?.nodes?.map(node => ({ ...node, ...createNodeHTML(node) })))
      setEdges(aggregateData?.edges)
    }
  }, [aggregateData])

  const [selectedGroups, setSelectedGroups] = useState([])

  const [groupAnchorEl, setGroupAnchorEl] = useState(null)
  const [requestAnchorEl, setRequestAnchorEl] = useState(null)
  const openGroupDropDown = Boolean(groupAnchorEl)
  const openRequestDropDown = Boolean(requestAnchorEl)
  const handleGroupClick = (event) => {
    document.body.classList.add('model-open')
    setGroupAnchorEl(event.currentTarget)
  }
  const handleGroupClose = () => {
    document.body.classList.remove('model-open')
    setGroupAnchorEl(null)
  }

  const handleRequestClick = (event) => {
    document.body.classList.add('model-open')
    setRequestAnchorEl(event.currentTarget)
  }
  const handleRequestClose = () => {
    document.body.classList.remove('model-open')
    setRequestAnchorEl(null)
  }

  const groupOptions = [
    {
      id: 1, group: 'washsales', name: 'Wash Sales', value: 'washsales'
    },
    {
      id: 2, group: 'settlement', name: 'Settlement', value: 'settlement'
    },
    {
      id: 3, group: 'asset-allocation', name: 'Asset Allocation', value: 'asset-allocation'
    },
    {
      id: 4, group: 'cash-program', name: 'Cash Program', value: 'cash-program'
    }
  ]

  const requestOptions = [
    {
      id: 1, name: 'Cash Raise', value: 'cash-raise'
    },
    {
      id: 2, name: 'Taxlot Swap', value: 'taxlot-swap'
    },
    {
      id: 3, name: 'Transition Analysis', value: 'transition-analysis'
    },
    {
      id: 4, name: 'Allocation Change', value: 'allocation-change'
    },
    {
      id: 5, name: 'Gain Analysis', value: 'gain-analysis'
    }
  ]

  const handleGroupChange = (value) => {
    if (['washsales', 'settlement'].includes(value)) {
      const nextList = selectedGroups.filter(group => group === value)?.length ? selectedGroups.filter(group => group !== value) : [value]
      setSelectedGroups(nextList)
      setNodes((nds) =>
        nds.map((node) => {
          if (nextList.length) {
            if (nextList?.some(selectedGroup => node?.nodeInfo?.group?.includes(selectedGroup))) {
              // it's important that you create a new object here
              // in order to notify react flow about the change
              node.style = { ...node.style, opacity: 1 }
            } else {
              node.style = { ...node.style, opacity: 0.2 }
            }
          } else {
            node.style = { ...node.style, opacity: 1 }
          }

          return node
        })
      )
    }
  }

  return (
    <>
      <ReactFlow
        className='static-react-flow'
        nodes={nodes}
        edges={edges}
        fitView
        defaultViewport={{ x: 0, y: 0, zoom: 1.5 }}
      />
      <Controls position='top-right' showInteractive={false}>
        <ControlButton>
          <IconButton
            aria-label='more'
            id='long-button'
            aria-controls={openGroupDropDown ? 'long-menu' : undefined}
            aria-expanded={openGroupDropDown ? 'true' : undefined}
            aria-haspopup='true'
            onClick={handleGroupClick}
          >
            <WorkspacesOutlinedIcon />
          </IconButton>
          <Menu
            id='long-menu'
            MenuListProps={{
              'aria-labelledby': 'long-button'
            }}
            anchorEl={groupAnchorEl}
            open={openGroupDropDown}
            onClose={handleGroupClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            {groupOptions.map((option) => (
              <MenuItem key={option.id} selected={selectedGroups.includes(option.value)} onClick={() => handleGroupChange(option.value)}>
                {option.name}
              </MenuItem>
            ))}
          </Menu>
        </ControlButton>
        <ControlButton>
          <IconButton
            aria-label='more'
            id='long-button'
            aria-controls={openRequestDropDown ? 'long-menu' : undefined}
            aria-expanded={openRequestDropDown ? 'true' : undefined}
            aria-haspopup='true'
            onClick={handleRequestClick}
          >
            <img src={clickIcon} alt='' height={14} width={14} />
          </IconButton>
          <Menu
            id='long-menu'
            MenuListProps={{
              'aria-labelledby': 'long-button'
            }}
            anchorEl={requestAnchorEl}
            open={openRequestDropDown}
            onClose={handleRequestClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            {requestOptions.map((option) => (
              <MenuItem key={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Menu>
        </ControlButton>
      </Controls>
    </>
  )
}

export default ({ aggregateData }) => (
  <ReactFlowProvider>
    <LayoutFlow aggregateData={aggregateData} />
  </ReactFlowProvider>
)
